// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-australian-book-stores-js": () => import("./../../../src/pages/australian-book-stores.js" /* webpackChunkName: "component---src-pages-australian-book-stores-js" */),
  "component---src-pages-boots-to-bliss-js": () => import("./../../../src/pages/boots-to-bliss.js" /* webpackChunkName: "component---src-pages-boots-to-bliss-js" */),
  "component---src-pages-boots-to-freedom-js": () => import("./../../../src/pages/boots-to-freedom.js" /* webpackChunkName: "component---src-pages-boots-to-freedom-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

