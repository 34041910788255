/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { sendPageView } from "./src/utils/googleAnalytics"

export const onRouteUpdate = ({ location }) => {
  // Minimum delay for reactHelmet's requestAnimationFrame
  const event = () => sendPageView({ location })
  console.log("event", event)
  setTimeout(event, 50)
}
