const sendEvent = (
  { eventType, category, action, label, value },
  callback = function () {}
) => {
  const { gtag } = window
  if (!!gtag) {
    const payload = {
      transport: !!navigator.sendBeacon ? "beacon" : "xhr",
      event_category: category,
      event_label: label,
      event_value: value,
      event_callback: callback,
      appVersion: `${process.env.CAL_VER}`,
    }
    gtag(eventType, action, payload)
  }
}

export const sendClickEvent = ({ label, value }, callback) => {
  sendEvent(
    {
      eventType: "event",
      category: "CTA",
      action: "click",
      label,
      value,
    },
    callback
  )
}

/**
 *
 * @param location
 */
export const sendPageView = ({ location }) => {
  const { gtag } = window
  if (!!gtag) {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined

    gtag("event", "page_view", {
      page_path: pagePath,
      appVersion: `${process.env.CAL_VER}`,
    })
  }
}
